html, body {
  height: 100%;
  color: #b4aaaa;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

body {
  background-color: #082127;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .content {
  display: block;
}

body .content .message-input {
  width: 100%;
  text-align: center;
  z-index: 1;
  position: absolute;
  bottom: 5%;
  left: 0;
}

body .content .message-input #message {
  width: 90%;
  height: 20px;
  color: #b4aaaa;
  text-align: center;
  background-color: #001;
  border: none;
  outline: none;
  padding: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 100;
}

body .content .message-input #message:focus {
  outline: none;
}

/*# sourceMappingURL=index.265e01d6.css.map */
